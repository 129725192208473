<template>
  <div class="max-w-screen-2xl mx-auto px-4 py-2 lg:px-4">
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <sort-label
          name="submissionNumber"
          :placeholder="$t('submission-number')"
          classes="hidden pr-1 w-20 xl:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="submittedBy"
          :placeholder="$t('submitter')"
          classes="flex px-1 w-40"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="name"
          :placeholder="$t('submission-form')"
          classes="hidden px-1 w-40 md:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="group"
          :placeholder="$t('group')"
          classes="hidden px-1 w-36 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="subgroup"
          :placeholder="$t('sub-group')"
          classes="hidden px-1 w-44 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="sub_subgroup"
          :placeholder="$t('sub-sub-group')"
          classes="hidden px-1 w-36 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="createdAt"
          :placeholder="$t('added')"
          classes="hidden px-1 w-28 sm:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="updatedAt"
          :placeholder="$t('updated')"
          classes="hidden sm:flex w-28"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
      </div>
      <div class="flex flex-row">
        <sort-label
          name="status"
          :placeholder="$t('status')"
          classes="flex justify-end w-30 sm:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
        <div class="flex justify-end w-20 sm:flex">
          <p class="font-semibold text-sm">{{ $t("action") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortLabel from "@/components/SortLabel";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { SortLabel },
  computed: {
    ...mapGetters(["currentUser"]),
    ...mapGetters("submissions", ["sort"]),
    isAdminOrEdit() {
      return (
        this.currentUser?.role?.submission?.admin ||
        this.currentUser?.role?.submission?.edit
      );
    },
  },

  methods: {
    ...mapMutations("submissions", ["sortSubmission", "toggleSort"]),
    onSort(by) {
      this.toggleSort();
      this.sortSubmission(by);
    },
  },
};
</script>
