<template>
  <button
    class="label cursor-pointer inline-flex items-center"
    :class="[`${classes}`]"
    @click="onChange(name)"
  >
    <p class="font-semibold text-sm mr-1">{{ placeholder }}</p>
    <div class="status hidden flex-col gap-0.5">
      <img
        v-if="sortData?.by === name && sortData?.asc"
        src="/chevron-up-dark.svg"
        alt="up"
        class="w-2"
      />
      <img
        v-if="sortData?.by !== name || !sortData?.asc"
        src="/chevron-up-light.svg"
        alt="up"
        class="w-2"
      />
      <img
        v-if="sortData?.by !== name || sortData?.asc"
        src="/chevron-down-light.svg"
        alt="down"
        class="w-2"
      />
      <img
        v-if="sortData?.by === name && !sortData?.asc"
        src="/chevron-down-dark.svg"
        alt="down"
        class="w-2"
      />
    </div>
  </button>
</template>

<script>
export default {
  props: ["classes", "name", "placeholder", "sortData"],
  emits: ["sort"],
  methods: {
    onChange(by) {
      this.$emit("sort", by);
    },
  },
};
</script>

<style scoped>
.label:hover .status {
  display: flex;
}
</style>
