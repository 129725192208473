<template>
  <div
    :class="{ show: show, hide: !show }"
    @click.prevent="closeMenu"
    class="
      menu
      absolute
      lg:static
      right-0
      top-12
      flex flex-col
      lg:flex-row
      bg-white
      z-50
      lg:w-auto
      w-52
      rounded-lg
    "
  >
    <router-link
      to="/"
      class="
        font-semibold
        hover:bg-WALIGHTBLUE-50
        lg:py-2 lg:px-2
        py-3
        px-4
        lg:rounded-md
        rounded-t-lg
        hover:rounded-t-lg
        text-WADARKBLUE-500 text-sm
        lg:mr-4
      "
      >{{ $t("dashboard") }}
    </router-link>
    <router-link
      v-if="isAdminOrEdit('submission') || isViewer('submission')"
      to="/submissions"
      class="
        font-semibold
        hover:bg-WALIGHTBLUE-50
        lg:py-2 lg:px-2
        py-3
        px-4
        lg:rounded-md
        text-WADARKBLUE-500 text-sm
        lg:mr-4
      "
      >{{ $t("submissions") }}
    </router-link>
    <router-link
      v-if="isAdminOrEdit('document') || isViewer('document')"
      to="/documents"
      class="
        hover:bg-WALIGHTBLUE-50
        font-semibold
        lg:py-2 lg:px-2
        py-3
        px-4
        lg:rounded-md
        text-WADARKBLUE-500 text-sm
        lg:mr-4
      "
      aria-current="page"
      >{{ $t("documents") }}
    </router-link>
    <router-link
      v-if="isAdmin('form')"
      to="/forms"
      class="
        font-semibold
        hover:bg-WALIGHTBLUE-50
        lg:py-2 lg:px-2
        py-3
        px-4
        lg:rounded-md
        text-WADARKBLUE-500 text-sm
        lg:mr-4
      "
      >{{ $t("forms") }}
    </router-link>

    <router-link
      v-if="isAdminOrEdit('group') || isViewer('group')"
      to="/groups"
      class="
        font-semibold
        hover:bg-WALIGHTBLUE-50
        lg:py-2 lg:px-2
        py-3
        px-4
        lg:rounded-md
        text-WADARKBLUE-500 text-sm
        lg:mr-4
      "
      >{{ $t("groups") }}
    </router-link>

    <router-link
      v-if="isAdmin('user')"
      to="/users"
      class="
        hover:bg-WALIGHTBLUE-50
        font-semibold
        lg:py-2 lg:px-2
        py-3
        px-4
        lg:rounded-md
        text-WADARKBLUE-500 text-sm
        lg:mr-4
      "
      aria-current="page"
      >{{ $t("users") }}
    </router-link>

    <router-link
      v-if="isAdmin('role')"
      to="/roles"
      class="
        font-semibold
        hover:bg-WALIGHTBLUE-50
        lg:py-2 lg:px-2
        py-3
        px-4
        lg:rounded-md
        text-WADARKBLUE-500 text-sm
      "
      >{{ $t("roles") }}
    </router-link>
    <button
      @click.prevent="$emit('openProfile')"
      class="
        text-left
        font-semibold
        hover:bg-WALIGHTBLUE-50
        lg:py-2 lg:px-2
        py-2
        px-4
        lg:rounded-md
        text-WADARKBLUE-500 text-sm
        lg:hidden
      "
    >
      {{ $t("settings") }}
    </button>
    <button
      @click="$emit('logout')"
      class="
        font-semibold
        hover:bg-WADARKBLUE-600
        lg:py-2 lg:px-2
        py-2
        px-4
        rounded-md
        bg-WADARKBLUE-500
        text-white text-sm text-center
        my-2
        inline-block
        lg:hidden
        w-28
        mx-2
      "
    >
      {{ $t("logout") }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["show", "isAdmin", "isAdminOrEdit", "isViewer"],
  emits: ["logout", "openProfile", "close"],
  methods: {
    closeMenu(e) {
      if (!this.$el.contains(e.target)) this.$emit("close", e);
    },
  },

  mounted() {
    window.document.addEventListener("click", this.closeMenu);
  },

  unmounted() {
    window.document.removeEventListener("click", this.closeMenu);
  },
};
</script>

<style scoped>
a.active {
  background-color: #f1f7f8;
}
</style>