<template>
  <div
    class="flex items-center text-sm gap-2 mb-2 uploaded text-gray-700 py-1.5 px-2 rounded cursor-pointer"
  >
    <span>{{ getShortFileName(file) }}</span>
    <button @click="$emit('onClick')" type="button">
      <img src="/trash.svg" alt="trash" />
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    getShortFileName(file) {
      let ext = "";
      if (file?.Key) {
        ext = file.Key.substr(file.Key.lastIndexOf(".") + 1);
      } else {
        ext = file.name.substr(file.name.lastIndexOf(".") + 1);
      }

      const shortName =
        file.name.length > 8 ? `${file.name.substr(0, 8)}...` : file.name;
      return file.name.length > 8 ? shortName + ext : file.name;
    },
  },
  emits: ["onClick"],
  props: ["file"],
};
</script>
