import http from "../../services/httpClient";

const state = {
  list: [],
  filteredGroups: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredGroups: (state) => state.filteredGroups,
};

const actions = {
  getGroups: async ({ commit }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.get(`/admin/groups`);
      commit("setLoading", false);

      commit("setList", data);
      commit("setFilteredGroup", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  deleteGroup: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.delete(`/admin/groups/${id}`);
      commit("setLoading", false);

      commit("removeGroup", id);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.put(`/admin/groups/${id}/status`, { status });

      commit("setLoading", false);
      commit("updateStatus", { status, id });
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  filterGroup: ({ commit, getters }, filterVal) => {
    const { name, user, status } = filterVal;

    const newFilter = getters["list"].filter(
      (group) =>
        group.name.toLowerCase().includes(name.toLowerCase()) &&
        group.status.toLowerCase().includes(status.toLowerCase()) &&
        group.users
          .map((u) => u.name.toLowerCase())
          .join(" ")
          .includes(user.toLowerCase())
    );

    commit("setFilteredGroup", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredGroup: (state, filteredGroups) =>
    (state.filteredGroups = filteredGroups),

  addGroup: (state, group) => {
    const idx = state.list.findIndex((g) => g._id === group._id);
    if (idx < 0) state.list.unshift(group);

    const filterIdx = state.filteredGroups.findIndex(
      (filteredGroup) => filteredGroup._id === group._id
    );
    if (filterIdx < 0) state.filteredGroups.unshift(group);
  },

  updateGroup: (state, group) => {
    const idx = state.list.findIndex((list) => list._id === group._id);
    const filterIdx = state.filteredGroups.findIndex(
      (filteredGroup) => filteredGroup._id.toString() === group._id.toString()
    );

    if (idx === -1) return;
    state.list.splice(idx, 1, group);

    if (filterIdx === -1) return;
    state.filteredGroups.splice(filterIdx, 1, group);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredGroups.findIndex(
      (filteredGroup) => filteredGroup._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredGroups[filterIdx].status = status;
  },

  removeGroup: (state, id) => {
    state.list = state.list.filter(
      (group) => group._id.toString() !== id.toString()
    );
    state.filteredGroups = state.filteredGroups.filter(
      (filteredGroup) => filteredGroup._id.toString() !== id.toString()
    );
  },

  assignUser: (state, { id, user }) => {
    const idx = state.list.findIndex(
      (group) => group._id.toString() === id.toString()
    );
    // const filterIdx = state.filteredGroups.findIndex(
    //   (group) => group._id.toString() === id.toString()
    // );

    if (idx < 0) return;
    state.list[idx].users.push(user);

    // if (filterIdx < 0) return;
    // state.filteredGroups[idx].users.push(user);
  },

  removeUserFromGroup: (state, { groupId, userId }) => {
    const idx = state.list.findIndex(
      (group) => group._id.toString() === groupId.toString()
    );
    const filteredIdx = state.filteredGroups.findIndex(
      (group) => group._id.toString() === groupId.toString()
    );

    if (idx < 0) return;
    state.list[idx].users = state.list[idx].users.filter(
      (user) => user.id.toString() !== userId.toString()
    );

    if (filteredIdx < 0) return;
    state.filteredGroups[idx].users = state.filteredGroups[idx].users.filter(
      (user) => user.id.toString() !== userId.toString()
    );
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),
  sortGroup: (state, by) => {
    state.sort.by = by;

    if (by === "users")
      state.filteredGroups = state.filteredGroups.sort((a, b) => {
        const sortA = a[by].length
          ? a[by].map((r) => r?.name)[0].toLowerCase()
          : "z";

        const sortB = b[by].length
          ? b[by].map((r) => r?.name)[0].toLowerCase()
          : "z";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else {
      state.filteredGroups = state.filteredGroups.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );
    }

    if (!state.sort.asc) state.filteredGroups = state.filteredGroups.reverse();
  },
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
