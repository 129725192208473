<template>
  <div>
    <Modal :show="currentUser && showProfileModal" @onClose="closeProfileModal">
      <template #header>
        {{ $t("edit") }}
      </template>
      <template #body
        ><profile-form
          @onClose="closeProfileModal"
          :me="currentUser"
          @change-password="openPasswordModal"
      /></template>
    </Modal>

    <Modal
      :show="currentUser && showPasswordModal"
      @onClose="showPasswordModal = false"
    >
      <template #header>
        {{ $t("change-password") }}
      </template>
      <template #body
        ><change-password
          @onClose="closeProfileModal"
          :me="currentUser"
          @change-password="openPasswordModal"
      /></template>
    </Modal>

    <nav class="bg-white">
      <div
        class="flex flex-row items-center justify-between max-w-screen-2xl mx-auto px-6 py-4 space-x-4"
      >
        <a href="/"
          ><img
            src="/wa-logo.png"
            class="h-7 md:h-8 lg:h-10 object-contain pr-6"
        /></a>
        <div
          id="header-menu"
          class="flex flex-col-reverse lg:flex-row items-end lg:items-center space-x-4 justify-between relative lg:w-full"
        >
          <large-menu
            @logout="logoutUser"
            @openProfile="openProfileModal"
            :show="showMenu"
            :isAdmin="isAdmin"
            :isAdminOrEdit="isAdminOrEdit"
            :isViewer="isViewer"
            @close="closeMenu"
          />

          <div class="flex items-center">
            <button
              @click.prevent="toggleMenu"
              class="relative w-9 h-9 flex justify-center items-center rounded-3xl font-bold bg-WAGREEN-500 px-3 py-2 text-white text-sm lg:hidden"
              ref="largeMenuButton"
            >
              {{ transformName(currentUser?.name) }}
            </button>

            <div class="relative hidden lg:block">
              <button
                @click="toggleSmallMenu"
                ref="smallMenuButton"
                class="relative w-9 h-9 flex justify-center items-center rounded-3xl font-bold bg-WAGREEN-500 px-3 py-2 text-white text-sm"
              >
                {{ transformName(currentUser?.name) }}
              </button>

              <small-menu
                @logout="logoutUser"
                @openProfile="openProfileModal"
                @closeMenu="closeSmallMenu"
                :show="showSmallMenu"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal.vue";
import ProfileForm from "@/components/profile/ProfileForm.vue";
import ChangePassword from "@/components/profile/ChangePassword.vue";
import SmallMenu from "./SmallMenu.vue";
import LargeMenu from "./LargeMenu.vue";

export default {
  computed: {
    ...mapGetters(["currentUser"]),
  },
  components: { Modal, ProfileForm, ChangePassword, SmallMenu, LargeMenu },
  data() {
    return {
      showMenu: false,
      showSmallMenu: false,
      showProfileModal: false,
      showPasswordModal: false,
    };
  },

  methods: {
    ...mapActions(["logout"]),
    transformName(name) {
      const letters = String(name)
        .toUpperCase()
        .split(" ")
        .map((i) => i.charAt(0));
      return letters.join("");
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    toggleSmallMenu() {
      this.showSmallMenu = !this.showSmallMenu;
    },

    logoutUser() {
      this.logout();
      window.location = "/login";
    },

    isAdminOrEdit(name) {
      return (
        this.currentUser &&
        ((this.currentUser?.role &&
          (this.currentUser?.role[name]?.admin ||
            this.currentUser?.role[name]?.edit)) ||
          !!this.currentUser?.isSuper)
      );
    },

    isViewer(name) {
      return (
        this.currentUser &&
        this.currentUser?.role &&
        (this.currentUser?.role[name]?.user ||
          this.currentUser?.role[name]?.group)
      );
    },

    isAdmin(name) {
      return (
        (this.currentUser?.role && this.currentUser?.role[name]?.admin) ||
        this.currentUser?.isSuper
      );
    },

    openProfileModal() {
      this.showProfileModal = true;
      this.showMenu = false;
      this.showSmallMenu = false;
    },

    closeProfileModal() {
      this.showProfileModal = false;
    },

    openPasswordModal() {
      this.showPasswordModal = true;
      this.showProfileModal = false;
    },

    closeMenu(e) {
      if (e.target === this.$refs.largeMenuButton) return;
      this.showMenu = false;
    },

    closeSmallMenu(e) {
      if (e.target === this.$refs.smallMenuButton) return;
      this.showSmallMenu = false;
    },
  },
};
</script>

<style scoped>
.menu,
.small-menu {
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.05);
}
/* .menu::after, */
.small-menu::after {
  content: "";
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  height: 18px;
  width: 18px;
  right: 10px;
  background-color: white;
  z-index: -1 !important;
}

@media screen and (min-width: 1024px) {
  .menu {
    box-shadow: none;
  }
}

@media screen and (max-width: 1024px) {
  .menu.show {
    display: flex !important;
  }
  .menu.hide {
    display: none !important ;
  }
}
</style>
