import { createStore } from "vuex";
import auth from "./modules/auth";
import groups from "./modules/groups";
import subgroups from "./modules/subgroups";
import sub_subgroups from "./modules/sub-subgroups";
import users from "./modules/users";
import roles from "./modules/roles";
import forms from "./modules/forms";
import submissions from "./modules/submissions";
import doctypes from "./modules/doctypes";
import documents from "./modules/documents";

export default createStore({
  modules: {
    auth,
    groups,
    users,
    subgroups,
    sub_subgroups,
    roles,
    forms,
    submissions,
    doctypes,
    documents,
  },

  devtools: true, // process.env.VUE_APP_NODE_ENV === "development",
});
