<template>
  <div class="bg-gray-50 flex flex-col justify-between min-h-screen">
    <div class="pb-3">
      <the-header
        v-if="!!currentUser && Object.keys(currentUser).length && showHeader"
      ></the-header>
      <router-view />
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TheFooter from "./components/TheFooter.vue";
import TheHeader from "./components/header/TheHeader.vue";

export default {
  data() {
    return {
      showHeader: true,
    };
  },
  methods: {
    ...mapActions(["fetchMe"]),
  },

  async created() {
    const accessToken = localStorage.getItem("accessToken");
    if (this.accessToken || accessToken) {
      await this.fetchMe();
    }
  },

  computed: {
    ...mapGetters(["currentUser", "accessToken"]),
  },

  components: {
    TheFooter,
    TheHeader,
  },

  watch: {
    $route(to) {
      if (
        to.fullPath === "/login" ||
        to.fullPath === "/password/forgotpassword" ||
        to.fullPath === "/password/resetpassword/:resettoken"
      )
        this.showHeader = false;
    },
  },
};
</script>

<style scoped>
.app {
  min-height: 87vh;
}
</style>