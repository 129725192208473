import http from "../../services/httpClient";

const state = {
  list: [],
  filteredSubmissions: [],
  dashboardData: {},
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  dashboardData: (state) => state.dashboardData,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredSubmissions: (state) => state.filteredSubmissions,
};

const actions = {
  getSubmissions: async ({ commit }, limit = "") => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.get(`/admin/submissions?limit=${limit}`);
      commit("setLoading", false);

      commit("setList", data);
      commit("setFilteredSubmission", data);

      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.put(`/admin/submissions/${id}/status`, {
        status,
      });

      commit("setLoading", false);
      commit("updateStatus", { status, id });

      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  getDashboardData: async ({ commit }) => {
    try {
      commit("setError", null);
      const { data } = await http.get(`/admin/submissions/dashboard`);

      commit("setDashboardData", data);
      return data;
    } catch (err) {
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  getSubmissionById: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.get(`/admin/submissions/${id}`);
      commit("setLoading", false);

      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
      return {};
    }
  },

  filterSubmission: ({ commit, getters }, filterVal) => {
    const {
      name,
      submissionNumber,
      group,
      subgroup,
      sub_subgroup,
      submittedBy,
      status,
    } = filterVal;

    const newFilter = getters["list"].filter((submission) => {
      const groupL = submission.form?.group ? submission.form.group?.name : "";
      const subgroupL = submission.form?.subgroup
        ? submission.form.subgroup?.name
        : "";

      const sub_subgroupL = submission.form?.sub_subgroup
        ? submission.form.sub_subgroup?.name
        : "";

      const submittedByL = submission.submittedBy
        ? submission.submittedBy?.name
        : "";

      return (
        submission.form.name.toLowerCase().includes(name.toLowerCase()) &&
        submission.submissionNumber
          .toString()
          .includes(submissionNumber.toString()) &&
        groupL.toLowerCase().includes(group.toLowerCase()) &&
        subgroupL.toLowerCase().includes(subgroup.toLowerCase()) &&
        sub_subgroupL.toLowerCase().includes(sub_subgroup.toLowerCase()) &&
        submittedByL.toLowerCase().includes(submittedBy.toLowerCase()) &&
        submission.status.toLowerCase().includes(status.toLowerCase())
      );
    });

    commit("setFilteredSubmission", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),
  setDashboardData: (state, data) => (state.dashboardData = data),
  setFilteredSubmission: (state, filteredSubmissions) =>
    (state.filteredSubmissions = filteredSubmissions),

  markAsUnread: (state, id) => {
    const idx = state.list.findIndex((s) => s._id === id);
    if (idx > -1) {
      state.list[idx].read = false;
    }
  },

  addSubmission: (state, submission) => {
    const idx = state.list.findIndex((g) => g._id === submission._id);
    if (idx < 0) state.list.unshift(submission);

    const filterIdx = state.filteredSubmissions.findIndex(
      (filteredSubmission) => filteredSubmission._id === submission._id
    );
    if (filterIdx < 0) state.filteredSubmissions.unshift(submission);
    state.dashboardData.todays = state.dashboardData.todays + 1;
    state.dashboardData.weeks = state.dashboardData.weeks + 1;
    state.dashboardData.months = state.dashboardData.months + 1;
    state.dashboardData.all = state.dashboardData.all + 1;
  },

  updateSubmission: (state, submission) => {
    const idx = state.list.findIndex((list) => list._id === submission._id);
    const filterIdx = state.filteredSubmissions.findIndex(
      (filteredSubmission) =>
        filteredSubmission._id.toString() === submission._id.toString()
    );

    if (idx === -1) return;
    state.list.splice(idx, 1, submission);

    if (filterIdx === -1) return;
    state.filteredSubmissions.splice(filterIdx, 1, submission);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredSubmissions.findIndex(
      (filteredSubmission) =>
        filteredSubmission._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredSubmissions[filterIdx].status = status;
  },

  removeSubmission: (state, id) => {
    state.list = state.list.filter(
      (submission) => submission._id.toString() !== id.toString()
    );
    state.filteredSubmissions = state.filteredSubmissions.filter(
      (filteredSubmission) =>
        filteredSubmission._id.toString() !== id.toString()
    );

    if (state.dashboardData.todays > 0)
      state.dashboardData.todays = state.dashboardData.todays - 1;

    if (state.dashboardData.weeks > 0)
      state.dashboardData.weeks = state.dashboardData.weeks - 1;

    if (state.dashboardData.months > 0)
      state.dashboardData.months = state.dashboardData.months - 1;

    if (state.dashboardData.all > 0)
      state.dashboardData.all = state.dashboardData.all - 1;
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),
  sortSubmission: (state, by) => {
    state.sort.by = by;

    if (by === "group" || by === "subgroup" || by === "sub_subgroup")
      state.filteredSubmissions = state.filteredSubmissions.sort((a, b) => {
        const sortA = a?.form[by] ? a.form[by]?.name : "Zzzz";
        const sortB = b?.form[by] ? b.form[by]?.name : "Zzzz";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else if (by === "name") {
      state.filteredSubmissions = state.filteredSubmissions.sort((a, b) =>
        a.form[by].toLowerCase() > b.form[by].toLowerCase() ? 1 : -1
      );
    } else if (by === "submissionNumber") {
      state.filteredSubmissions = state.filteredSubmissions.sort((a, b) =>
        a[by] > b[by] ? 1 : -1
      );
    } else if (by === "submittedBy") {
      state.filteredSubmissions = state.filteredSubmissions.sort((a, b) => {
        const sortA = a[by] ? a[by]?.name : "";
        const sortB = b[by] ? b[by]?.name : "";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    } else
      state.filteredSubmissions = state.filteredSubmissions.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );

    if (!state.sort.asc)
      state.filteredSubmissions = state.filteredSubmissions.reverse();
  },
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
