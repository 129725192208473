import http from "../../services/httpClient";

const state = {
  list: [],
  filteredSubSubGroups: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredSubSubGroups: (state) => state.filteredSubSubGroups,
};

const actions = {
  getSubSubGroups: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.get(`/admin/sub-subgroups/subgroup/${id}`);
      commit("setLoading", false);

      commit("setList", data);
      commit("setFilteredSubSubGroup", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.put(`/admin/sub-subgroups/${id}/status`, {
        status,
      });

      commit("setLoading", false);
      commit("updateStatus", { status, id });
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  deleteSubSubGroup: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.delete(`/admin/sub-subgroups/${id}`);
      commit("setLoading", false);

      commit("removeSubSubGroup", id);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  filterSubSubGroup: ({ commit, getters }, filterVal) => {
    const { name, user, status } = filterVal;

    const newFilter = getters["list"].filter(
      (sub) =>
        sub.name.toLowerCase().includes(name.toLowerCase()) &&
        sub.status.toLowerCase().includes(status.toLowerCase()) &&
        sub.users
          .map((u) => u.name.toLowerCase())
          .join(" ")
          .includes(user.toLowerCase())
    );

    commit("setFilteredSubSubGroup", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredSubSubGroup: (state, filteredSubSubGroups) =>
    (state.filteredSubSubGroups = filteredSubSubGroups),

  addSubSubGroup: (state, subgroup) => {
    state.list.unshift(subgroup);

    const filterIdx = state.filteredSubSubGroups.findIndex(
      (filteredSubSub) => filteredSubSub._id === subgroup._id
    );
    if (filterIdx < 0) state.filteredSubSubGroups.unshift(subgroup);
  },

  updateSubSubGroup: (state, subgroup) => {
    const idx = state.list.findIndex((li) => li._id === subgroup._id);
    const filterIdx = state.filteredSubSubGroups.findIndex(
      (filteredSubSub) => filteredSubSub._id === subgroup._id
    );
    if (idx === -1) return;

    state.list.splice(idx, 1, subgroup);
    if (filterIdx !== -1)
      state.filteredSubSubGroups.splice(filterIdx, 1, subgroup);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredSubSubGroups.findIndex(
      (filteredSubSub) => filteredSubSub._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredSubSubGroups[filterIdx].status = status;
  },

  removeSubSubGroup: (state, id) => {
    state.list = state.list.filter(
      (s_subgroup) => s_subgroup._id.toString() !== id.toString()
    );
    state.filteredSubSubGroups = state.filteredSubSubGroups.filter(
      (filteredSSGroup) => filteredSSGroup._id.toString() !== id.toString()
    );
  },

  assignUser: (state, { id, user }) => {
    const idx = state.list.findIndex(
      (group) => group._id.toString() === id.toString()
    );

    if (idx < 0) return;
    state.list[idx].users.push(user);
  },

  removeAssignedUser: (state, { sub_subgroupId, userId }) => {
    const idx = state.list.findIndex(
      (sub_subgroup) =>
        sub_subgroup._id.toString() === sub_subgroupId.toString()
    );
    const filteredIdx = state.filteredSubSubGroups.findIndex(
      (sub_subgroup) =>
        sub_subgroup._id.toString() === sub_subgroupId.toString()
    );

    if (idx < 0) return;
    state.list[idx].users = state.list[idx].users.filter(
      (user) => user.id.toString() !== userId.toString()
    );

    if (filteredIdx < 0) return;
    state.filteredSubSubGroups[idx].users = state.filteredSubSubGroups[
      idx
    ].users.filter((user) => user.id.toString() !== userId.toString());
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),

  sortSubSubGroup: (state, by) => {
    state.sort.by = by;

    if (by === "users")
      state.filteredSubSubGroups = state.filteredSubSubGroups.sort((a, b) => {
        const sortA = a[by].length
          ? a[by].map((r) => r?.name)[0].toLowerCase()
          : "z";

        const sortB = b[by].length
          ? b[by].map((r) => r?.name)[0].toLowerCase()
          : "z";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else {
      state.filteredSubSubGroups = state.filteredSubSubGroups.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );
    }
    if (!state.sort.asc)
      state.filteredSubSubGroups = state.filteredSubSubGroups.reverse();
  },

  // toggle sort ( asc and desc )
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
  clearSubSubGroup: (state) => {
    state.list = [];
    state.filteredSubSubGroups = [];
    state.loading = false;
    state.error = null;
  },
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
