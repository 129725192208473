<template>
  <router-link
    :to="`/submissions/${submission._id}`"
    class="block bg-white cursor-pointer hover:bg-WALIGHTBLUE-50 max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-2xl shadow-gray-100 lg:px-4"
  >
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <div class="hidden pr-1 w-20 xl:flex">
          <p
            :class="[
              isAdmin
                ? submission?.read
                  ? 'font-thin'
                  : 'font-semibold'
                : 'font-thin',
            ]"
            class="text-WADARKBLUE-500 text-sm"
          >
            {{ submission.submissionNumber }}
          </p>
        </div>

        <div class="flex px-1 w-40">
          <p
            :class="[
              isAdmin
                ? submission?.read
                  ? 'font-thin'
                  : 'font-semibold'
                : 'font-thin',
            ]"
            class="text-WADARKBLUE-500 text-sm truncate"
          >
            {{ submission?.submittedBy?.name }}
          </p>
        </div>

        <div
          :class="[
            isAdmin
              ? submission?.read
                ? 'font-thin'
                : 'font-semibold'
              : 'font-thin',
          ]"
          class="hidden px-1 text-WADARKBLUE-500 w-40 md:flex"
        >
          <p class="text-sm truncate">
            {{ submission.form?.name }}
          </p>
        </div>

        <div class="hidden px-1 w-36 lg:flex">
          <p
            :class="[
              isAdmin
                ? submission?.read
                  ? 'font-thin'
                  : 'font-semibold'
                : 'font-thin',
            ]"
            class="px-1 text-WADARKBLUE-500 text-sm truncate"
          >
            {{ submission.form?.group?.name || " " }}
          </p>
        </div>

        <div class="hidden w-44 lg:flex">
          <p
            :class="[
              isAdmin
                ? submission?.read
                  ? 'font-thin'
                  : 'font-semibold'
                : 'font-thin',
            ]"
            class="px-1 text-WADARKBLUE-500 text-sm truncate"
          >
            {{ submission.form?.subgroup?.name || " " }}
          </p>
        </div>

        <div class="hidden w-36 lg:flex">
          <p
            :class="[
              isAdmin
                ? submission?.read
                  ? 'font-thin'
                  : 'font-semibold'
                : 'font-thin',
            ]"
            class="px-1 text-WADARKBLUE-500 text-sm truncate"
          >
            {{ submission.form?.sub_subgroup?.name || " " }}
          </p>
        </div>

        <div class="hidden px-1 sm:flex w-28">
          <p
            :class="[
              isAdmin
                ? submission?.read
                  ? 'font-thin'
                  : 'font-semibold'
                : 'font-thin',
            ]"
            class="text-WADARKBLUE-500 text-sm"
          >
            {{ formatDate(submission?.createdAt) }}
          </p>
        </div>

        <div class="hidden px-1 w-36 md:flex">
          <p
            :class="[
              isAdmin
                ? submission?.read
                  ? 'font-thin'
                  : 'font-semibold'
                : 'font-thin',
            ]"
            class="text-WADARKBLUE-500 text-sm"
          >
            {{ formatDate(submission.updatedAt) }}
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-row">
          <div class="flex relative justify-end w-30 sm:flex">
            <button
              :disabled="!isOwner && !isSuperOrAdminOrEdit"
              @click.prevent="openStatusModal(submission)"
              :class="[
                isAdmin
                  ? submission?.read
                    ? 'font-thin'
                    : 'font-semibold'
                  : 'font-thin',
              ]"
              class="flex text-WAGREEN-500 text-sm items-center"
            >
              {{ submission.status }}
              <img
                v-if="isOwner || isSuperOrAdminOrEdit"
                class="w-2 ml-1"
                src="/chevron-down-dark.svg"
                alt="chevron"
              />
            </button>
            <status-modal
              v-if="showStatus.status && showStatus.id === submission._id"
              v-model="status"
              :data="statusData"
              @close="closeStatusModal"
            ></status-modal>
          </div>
        </div>
        <div class="relative flex justify-end w-20 sm:flex">
          <option-dropdown-new
            v-if="isSuperOrAdminOrEdit || isOwner"
            @btnClick="openOptionDropDown(submission._id)"
            @onClose="closeOptionDropdown"
            :show="
              showOptionDropdown.status &&
              showOptionDropdown.id === submission._id
            "
          >
            <button
              @click.prevent="$router.push(`/submissions/${submission?._id}`)"
              class="px-4 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("view") }}
            </button>

            <button
              @click.prevent="$emit('edit', submission)"
              class="px-4 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("edit") }}
            </button>

            <button
              v-if="isAdmin && submission?.read"
              @click.prevent="markAsUnread"
              class="px-4 whitespace-nowrap py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("Mark as unread") }}
            </button>

            <button
              @click.prevent="openConfirmModal(submission._id)"
              class="px-4 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
            >
              {{ $t("delete") }}
            </button>
          </option-dropdown-new>

          <div
            class="absolute -bottom-16 w-80"
            v-if="showConfirm.status && showConfirm.id === submission._id"
          >
            <confirm-modal
              :show="showConfirm.status && showConfirm.id === submission._id"
              @no="closeConfirmModal"
              @yes="confirmDelete(submission._id)"
            ></confirm-modal>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import StatusModal from "../StatusModal.vue";
import ConfirmModal from "../ConfirmModal.vue";
import OptionDropdownNew from "../shared/option-dropdown/OptionDropdownNew.vue";
import httpClient from "../../services/httpClient";

export default {
  computed: {
    ...mapGetters(["currentUser"]),
    isSuperOrAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.isSuper ||
          this.currentUser?.role?.submission?.admin ||
          this.currentUser?.role?.submission?.edit)
      );
    },
    isOwner() {
      return (
        this.currentUser?._id?.toString() ===
        this.submission?.submittedBy?._id?.toString()
      );
    },
    isAdmin() {
      return this.currentUser && this.currentUser?.role?.submission?.admin;
    },
  },

  components: { StatusModal, ConfirmModal, OptionDropdownNew },

  data() {
    return {
      statusData: [
        { id: 1, value: "Eingereicht" },
        { id: 2, value: "In Prüfung" },
        { id: 2, value: "Abgeschlossen" },
      ],
      status: "",
      showStatus: { status: false, id: "" },
      showOptionDropdown: { status: false, id: "" },
      showConfirm: { status: false, id: "" },
    };
  },

  methods: {
    ...mapActions("submissions", ["changeStatus"]),
    ...mapMutations("submissions", ["removeSubmission"]),
    formatDate(date) {
      const d = new Date(date);
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${day}.${mo}.${ye}`;
    },

    openStatusModal(submission) {
      this.showStatus.id = submission._id;
      this.showStatus.status = true;
    },

    closeStatusModal(e) {
      if (!e) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }
    },

    openOptionDropDown(id) {
      if (this.showStatus.status) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }

      this.showOptionDropdown.status = true;
      this.showOptionDropdown.id = id;
    },

    closeOptionDropdown(e) {
      if (!e) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }
    },

    async deleteSubmission(id) {
      try {
        this.deleting = true;
        await httpClient.delete(`/admin/submissions/${this.submission._id}`);

        this.removeSubmission(id);
      } catch (err) {
        this.deleting = false;
        console.error(err);
      }
    },

    confirmDelete(id) {
      this.closeConfirmModal();
      this.deleteSubmission(id);
    },

    openConfirmModal(id) {
      this.showConfirm.status = true;
      this.showConfirm.id = id;
    },

    closeConfirmModal(e) {
      if (!e) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      }
    },

    async markAsUnread() {
      try {
        this.closeOptionDropdown();
        this.$emit("mark-as-unread", this.submission?._id);
        await await httpClient.patch(
          `/admin/submissions/${this.submission._id}/mark-as-unread`
        );
      } catch (err) {
        console.log(err);
      }
    },
  },

  props: ["submission"],

  emits: ["edit"],

  mounted() {
    document.addEventListener("click", this.closeStatusModal);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeStatusModal);
  },

  watch: {
    async status(status) {
      await this.changeStatus({ status, id: this.submission._id });
    },
  },
};
</script>
