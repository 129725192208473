<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>
      {{
        selectedSubmission
          ? `${$t("update")} '${selectedSubmission?.form?.name}'`
          : $t("add-submission")
      }}
    </template>
    <template #body
      ><SubmissionForm @onClose="closeModal" :submission="selectedSubmission"
    /></template>
  </Modal>

  <Loader
    v-if="(loading && list.length === 0) || (list.length && list.length > 10)"
  />
  <template v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div
        class="
          w-full
          block
          sm:grid sm:grid-cols-2
          md:grid-cols-3
          lg:grid-cols-4
          gap-7 gap-y-7
          sm:gap-y-0
          md:justify-between md:items-center
        "
      >
        <div class="bg-WALIGHTGREY-500 rounded-lg py-3 px-4 mt-4 lg:mt-0">
          <h4 class="text-WADARKBLUE-500 mb-4 whitespace-nowrap text-xs">
            {{ $t("submissions-today") }}
          </h4>
          <div class="flex justify-between items-center">
            <img src="/folder-icon.svg" alt="folder" />
            <h1 class="text-3xl font-bold text-WADARKBLUE-500">
              {{ fullNumber(dashboardData?.todays) }}
            </h1>
          </div>
        </div>
        <div class="bg-WALIGHTBLUE-500 rounded-lg py-3 px-4 mt-4 lg:mt-0">
          <h4 class="text-WADARKBLUE-500 mb-4 whitespace-nowrap text-xs">
            {{ $t("submissions-this-week") }}
          </h4>
          <div class="flex justify-between items-center">
            <img src="/folder-icon.svg" alt="folder" />
            <h1
              class="text-3xl font-bold text-WADARKBLUE-500 whitespace-nowrap"
            >
              {{ fullNumber(dashboardData?.weeks) }}
            </h1>
          </div>
        </div>
        <div class="bg-WAGREEN-500 rounded-lg py-3 px-4 mt-4 lg:mt-0">
          <h4 class="text-white mb-4 whitespace-nowrap text-xs">
            {{ $t("submissions-this-month") }}
          </h4>
          <div class="flex justify-between items-center">
            <img src="/folder-icon-white.svg" alt="folder" />
            <h1 class="text-3xl font-bold text-white">
              {{ fullNumber(dashboardData?.months) }}
            </h1>
          </div>
        </div>
        <div class="bg-WADARKBLUE-500 rounded-lg py-3 px-4 mt-4 lg:mt-0">
          <h4 class="text-white mb-4 whitespace-nowrap text-xs">
            {{ $t("Submissions-all-time") }}
          </h4>
          <div class="flex justify-between items-center">
            <img src="/folder-icon-white.svg" alt="folder" />
            <h1 class="text-3xl font-bold text-white">
              {{ fullNumber(dashboardData?.all) }}
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <button
            @click="$router.push('/submissions/new')"
            class="
              bg-WAORANGE-500
              hover:bg-WAORANGE-400
              mr-2
              px-4
              py-2
              rounded-lg
              text-white text-xs
              uppercase
            "
          >
            + {{ $t("add-submission") }}
          </button>
        </div>
        <SubmissionFilter />
      </div>
    </section>

    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <SubmissionTableRow />
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredSubmissions.length > 0">
        <Submission
          @edit="onEdit"
          v-for="submission in filteredSubmissions"
          :key="submission._id"
          :submission="submission"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </template>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";
import SubmissionTableRow from "@/components/submissions/SubmissionTableRow.vue";
import Submission from "@/components/submissions/Submission.vue";
import SubmissionForm from "@/components/submissions/SubmissionForm.vue";
import SubmissionFilter from "@/components/submissions/SubmissionFilter.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SubmissionFilter,
    SubmissionTableRow,
    Submission,
    SubmissionForm,
    Modal,
    Loader,
  },
  computed: {
    ...mapGetters("submissions", [
      "list",
      "filteredSubmissions",
      "dashboardData",
      "loading",
      "error",
    ]),
    ...mapGetters(["currentUser"]),
    isAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.submission?.admin ||
          this.currentUser?.role?.submission?.edit ||
          this.currentUser?.isSuper)
      );
    },
  },
  async created() {
    await Promise.all([
      this.getSubmissions(process.env.VUE_APP_LIMIT),
      this.getDashboardData(),
    ]);
  },

  data() {
    return {
      showModal: false,
      selectedSubmission: null,
    };
  },
  methods: {
    ...mapActions("submissions", ["getSubmissions", "getDashboardData"]),
    openModal() {
      this.showModal = true;

      // set to null when adding new submission
      this.selectedSubmission = null;
    },
    closeModal() {
      this.showModal = false;
      this.selectedSubmission = null;
    },
    onEdit(submission) {
      this.showModal = true;
      this.selectedSubmission = null;
      this.selectedSubmission = submission;
    },

    fullNumber: (number) => number?.toString()?.padStart(4, "0"),
  },
};
</script>
