import axios from "axios";
import store from "../store";

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const RefreshInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
  },
});

RefreshInstance.interceptors.response.use(null, async (error) => {
  const unAuthorizedError =
    error.response && error.response.status && error.response.status === 401;

  if (unAuthorizedError) {
    logoutFunc();
  }

  return Promise.reject();
});

const logoutFunc = () => {
  store.dispatch("logout");
  window.location.reload();
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(null, async (error) => {
  const unAuthorizedError =
    error?.response &&
    error?.response?.status &&
    error?.response?.status === 401;

  if (unAuthorizedError) {
    if (!error.config.url.endsWith("refresh-access-token")) {
      try {
        await store.dispatch(
          "refreshAccessToken",
          localStorage.getItem("refreshToken")
        );

        window.location.reload();
        return Promise.reject();
      } catch (_err) {
        return Promise.reject(_err);
      }
    } else {
      logoutFunc();
    }
  }

  return Promise.reject(error);
});

export default axiosInstance;
