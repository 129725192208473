import http from "../../services/httpClient";

const state = {
  list: [],
  filteredDocTypes: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredDocTypes: (state) => state.filteredDocTypes,
};

const actions = {
  getDocTypes: async ({ commit }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.get(`/doctypes`);

      commit("setLoading", false);
      commit("setList", data);
      commit("setFilteredDocTypes", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err?.error || err?.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.put(`/doctypes/${id}/status`, { status });

      commit("setLoading", false);
      commit("updateStatus", { status, id });
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err?.error || err?.message
      );
    }
  },

  deleteDocType: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.delete(`/doctypes/${id}`);

      commit("setLoading", false);
      commit("removeDocType", id);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  filterDocType: ({ commit, getters }, filterVal) => {
    const { name, user, status } = filterVal;

    const newFilter = getters["list"].filter(
      (doctype) =>
        doctype.name.toLowerCase().includes(name.toLowerCase()) &&
        doctype.status.toLowerCase().includes(status.toLowerCase()) &&
        doctype.users
          .map((u) => u.name.toLowerCase())
          .join(" ")
          .includes(user.toLowerCase())
    );

    commit("setFilteredDocTypes", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredDocTypes: (state, filteredDocTypes) =>
    (state.filteredDocTypes = filteredDocTypes),

  addDocType: (state, doctype) => {
    const idx = state.list.findIndex((g) => g._id === doctype._id);
    if (idx < 0) state.list.unshift(doctype);

    const filterIdx = state.filteredDocTypes.findIndex(
      (filteredDoc) => filteredDoc._id === doctype._id
    );
    if (filterIdx < 0) state.filteredDocTypes.unshift(doctype);
  },

  updateDocType: (state, doctype) => {
    const idx = state.list.findIndex((list) => list._id === doctype._id);
    const filterIdx = state.filteredDocTypes.findIndex(
      (filteredDoc) => filteredDoc._id.toString() === doctype._id.toString()
    );

    if (idx === -1) return;
    state.list.splice(idx, 1, doctype);

    if (filterIdx === -1) return;
    state.filteredDocTypes.splice(filterIdx, 1, doctype);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredDocTypes.findIndex(
      (filterDocType) => filterDocType._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredDocTypes[filterIdx].status = status;
  },

  removeDocType: (state, id) => {
    state.list = state.list.filter(
      (doctype) => doctype._id.toString() !== id.toString()
    );
    state.filteredDocTypes = state.filteredDocTypes.filter(
      (filteredDoc) => filteredDoc._id.toString() !== id.toString()
    );
  },

  assignUser: (state, { id, user }) => {
    const idx = state.list.findIndex(
      (doctype) => doctype._id.toString() === id.toString()
    );
    // const filterIdx = state.filteredDocTypes.findIndex(
    //   (doctype) => doctype._id.toString() === id.toString()
    // );

    if (idx < 0) return;
    state.list[idx].users.push(user);

    // if (filterIdx < 0) return;
    // state.filteredDocTypes[idx].users.push(user);
  },

  removeUserFromDoctype: (state, { doctypeId, userId }) => {
    const idx = state.list.findIndex(
      (doctype) => doctype._id.toString() === doctypeId.toString()
    );
    const filteredIdx = state.filteredDocTypes.findIndex(
      (doctype) => doctype._id.toString() === doctypeId.toString()
    );

    if (idx < 0) return;
    state.list[idx].users = state.list[idx].users.filter(
      (user) => user.id.toString() !== userId.toString()
    );

    if (filteredIdx < 0) return;
    state.filteredDocTypes[idx].users = state.filteredDocTypes[
      idx
    ].users.filter((user) => user.id.toString() !== userId.toString());
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),
  sortDoctype: (state, by) => {
    state.sort.by = by;

    if (by === "users")
      state.filteredDocTypes = state.filteredDocTypes.sort((a, b) => {
        const sortA = a[by].length
          ? a[by].map((r) => r?.name)[0].toLowerCase()
          : "z"; // z is to put empty field at the end

        const sortB = b[by].length
          ? b[by].map((r) => r?.name)[0].toLowerCase()
          : "z"; // z is to put empty field at the end

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else {
      state.filteredDocTypes = state.filteredDocTypes.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );
    }

    if (!state.sort.asc)
      state.filteredDocTypes = state.filteredDocTypes.reverse();
  },
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
