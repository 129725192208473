import http from "../../services/httpClient";

const state = {
  list: [],
  filteredSubGroups: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredSubGroups: (state) => state.filteredSubGroups,
};

const actions = {
  getSubGroups: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.get(`/admin/subgroups/group/${id}`);
      commit("setLoading", false);

      commit("setList", data);
      commit("setFilteredSubGroup", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.put(`/admin/subgroups/${id}/status`, {
        status,
      });

      commit("setLoading", false);
      commit("updateStatus", { status, id });
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  deleteSubGroup: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.delete(`/admin/subgroups/${id}`);
      commit("setLoading", false);

      commit("removeSubGroup", id);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  filterSubGroup: ({ commit, getters }, filterVal) => {
    const { name, user, status } = filterVal;

    const newFilter = getters["list"].filter(
      (sub) =>
        sub.name.toLowerCase().includes(name.toLowerCase()) &&
        sub.status.toLowerCase().includes(status.toLowerCase()) &&
        sub.users
          .map((u) => u.name.toLowerCase())
          .join(" ")
          .includes(user.toLowerCase())
    );

    commit("setFilteredSubGroup", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredSubGroup: (state, filteredSubGroups) =>
    (state.filteredSubGroups = filteredSubGroups),

  addSubGroup: (state, subgroup) => {
    state.list.unshift(subgroup);

    const filterIdx = state.filteredSubGroups.findIndex(
      (filteredSubgroup) => filteredSubgroup._id === subgroup._id
    );
    if (filterIdx < 0) state.filteredSubGroups.unshift(subgroup);
  },

  updateSubGroup: (state, subgroup) => {
    const idx = state.list.findIndex((li) => li._id === subgroup._id);
    const filterIdx = state.filteredSubGroups.findIndex(
      (filteredSubgroup) => filteredSubgroup._id === subgroup._id
    );
    if (idx === -1) return;

    state.list.splice(idx, 1, subgroup);
    if (filterIdx !== -1)
      state.filteredSubGroups.splice(filterIdx, 1, subgroup);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredSubGroups.findIndex(
      (filteredSubgroup) => filteredSubgroup._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredSubGroups[filterIdx].status = status;
  },

  removeSubGroup: (state, id) => {
    state.list = state.list.filter(
      (subgroup) => subgroup._id.toString() !== id.toString()
    );
    state.filteredSubGroups = state.filteredSubGroups.filter(
      (filteredSubgroup) => filteredSubgroup._id.toString() !== id.toString()
    );
  },

  assignUser: (state, { id, user }) => {
    const idx = state.list.findIndex(
      (group) => group._id.toString() === id.toString()
    );

    if (idx < 0) return;
    state.list[idx].users.push(user);
  },

  removeUserFromSubGroup: (state, { groupId, userId }) => {
    const idx = state.list.findIndex(
      (group) => group._id.toString() === groupId.toString()
    );
    const filteredIdx = state.filteredSubGroups.findIndex(
      (group) => group._id.toString() === groupId.toString()
    );

    if (idx < 0) return;
    state.list[idx].users = state.list[idx].users.filter(
      (user) => user.id.toString() !== userId.toString()
    );

    if (filteredIdx < 0) return;
    state.filteredSubGroups[idx].users = state.filteredSubGroups[
      idx
    ].users.filter((user) => user.id.toString() !== userId.toString());
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),

  sortSubGroup: (state, by) => {
    state.sort.by = by;

    if (by === "users")
      state.filteredSubGroups = state.filteredSubGroups.sort((a, b) => {
        const sortA = a[by].length
          ? a[by].map((r) => r?.name)[0].toLowerCase()
          : "z";

        const sortB = b[by].length
          ? b[by].map((r) => r?.name)[0].toLowerCase()
          : "z";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else {
      state.filteredSubGroups = state.filteredSubGroups.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );
    }

    if (!state.sort.asc)
      state.filteredSubGroups = state.filteredSubGroups.reverse();
  },

  // toggle sort ( asc and desc )
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
  clearSubGroup: (state) => {
    state.list = [];
    state.filteredSubGroups = [];
    state.loading = false;
    state.error = null;
  },
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
