import { accessTokenKey, refreshTokenKey } from "../../services/authServcies";
import http, { RefreshInstance } from "../../services/httpClient";

const state = {
  accessToken: localStorage.getItem(accessTokenKey) || null,
  refreshToken: localStorage.getItem(refreshTokenKey) || null,
  currentUser: {},
  error: null,
  loading: false,
};

const getters = {
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  currentUser: (state) => state.currentUser,
  loading: (state) => state.loading,
  error: (state) => state.error,
};

const actions = {
  login: async ({ commit }, user) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.post(`/auth/login`, user);

      commit("setLoading", false);
      commit("setAccessToken", data[accessTokenKey]);
      commit("setRefreshToken", data[refreshTokenKey]);
      commit("setCurrentUser", data.user);
      localStorage.setItem(accessTokenKey, data[accessTokenKey]);
      localStorage.setItem(refreshTokenKey, data[refreshTokenKey]);
      return data.user;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err?.response && err?.response?.data?.error
          ? err?.response?.data?.error
          : err?.error || err?.message
      );
    }
  },

  logout: async ({ commit }) => {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);

    await http.get(`/auth/logout`);

    commit("setAccessToken", null);
    commit("setRefreshToken", null);
    commit("setCurrentUser", {});
  },

  refreshAccessToken: async ({ commit }, refreshToken) => {
    try {
      const { data } = await RefreshInstance.get(`/auth/refresh-access-token`, {
        headers: { Authorization: `Bearer ${refreshToken}` },
      });

      commit("setLoading", false);
      commit("setAccessToken", data[accessTokenKey]);
      commit("setRefreshToken", data[refreshTokenKey]);
      commit("setCurrentUser", data.user);
      localStorage.setItem(accessTokenKey, data[accessTokenKey]);
      localStorage.setItem(refreshTokenKey, data[refreshTokenKey]);

      return data;
    } catch (err) {
      commit("setLoading", false);

      return null;
    }
  },

  fetchMe: async ({ commit }) => {
    try {
      const { data } = await http.get(`/auth/users/me`);

      commit("setCurrentUser", data);
      return data;
    } catch (err) {
      commit(
        "setError",
        err?.response && err?.response?.data?.error
          ? err?.response?.data?.error
          : err?.error || err?.message
      );
    }
  },

  updateMe: async ({ commit }, me) => {
    commit("setError", null);
    commit("setLoading", true);
    try {
      const { data } = await http.put(`/auth/me/update`, me);

      commit("setCurrentUser", data);
      commit("setLoading", false);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err?.response && err?.response?.data?.error
          ? err?.response?.data?.error
          : err?.error || err?.message
      );
      return null;
    }
  },

  changePassword: async ({ commit }, passowrdData) => {
    commit("setError", null);
    commit("setLoading", true);

    try {
      const { data } = await http.put(`/auth/me/password`, passowrdData);

      commit("setLoading", false);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err?.response && err?.response?.data?.error
          ? err?.response?.data?.error
          : err?.error || err?.message
      );
      return null;
    }
  },
};

const mutations = {
  setAccessToken: (state, accessToken) => (state.accessToken = accessToken),
  setRefreshToken: (state, refreshToken) => (state.refreshToken = refreshToken),
  setCurrentUser: (state, currentUser) => (state.currentUser = currentUser),
  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
