import http from "../../services/httpClient";

const state = {
  list: [],
  filteredDocuments: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredDocuments: (state) => state.filteredDocuments,
};

const actions = {
  getDocuments: async ({ commit }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.get(`/documents`);

      commit("setLoading", false);
      commit("setList", data);
      commit("setFilteredDocuments", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.put(`/documents/${id}/status`, { status });

      commit("setLoading", false);
      commit("updateStatus", { status, id });
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  deleteDocument: async ({ commit }, id) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.delete(`/documents/${id}`);

      commit("setLoading", false);
      commit("removeDocument", id);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  filterDocument: ({ commit, getters }, filterVal) => {
    const { name, doctype, group, subgroup, sub_subgroup, status } = filterVal;

    const newFilter = getters["list"].filter((document) => {
      const doctypeL = document.doctype ? document.doctype?.name : "";
      const groupL = document.group ? document.group?.name : "";
      const subgroupL = document.subgroup ? document.subgroup?.name : "";
      const sub_subgroupL = document.sub_subgroup
        ? document.sub_subgroup?.name
        : "";

      return (
        document.name.toLowerCase().includes(name.toLowerCase()) &&
        doctypeL.toLowerCase().includes(doctype.toLowerCase()) &&
        groupL.toLowerCase().includes(group.toLowerCase()) &&
        subgroupL.toLowerCase().includes(subgroup.toLowerCase()) &&
        sub_subgroupL.toLowerCase().includes(sub_subgroup.toLowerCase()) &&
        document.status.toLowerCase().includes(status.toLowerCase())
      );
    });

    commit("setFilteredDocuments", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredDocuments: (state, filteredDocuments) =>
    (state.filteredDocuments = filteredDocuments),

  addDocument: (state, document) => {
    const idx = state.list.findIndex((g) => g._id === document._id);
    if (idx < 0) state.list.unshift(document);

    const filterIdx = state.filteredDocuments.findIndex(
      (filteredDoc) => filteredDoc._id === document._id
    );
    if (filterIdx < 0) state.filteredDocuments.unshift(document);
  },

  updateDocument: (state, document) => {
    const idx = state.list.findIndex((list) => list._id === document._id);
    const filterIdx = state.filteredDocuments.findIndex(
      (filteredDoc) => filteredDoc._id.toString() === document._id.toString()
    );

    if (idx === -1) return;
    state.list.splice(idx, 1, document);

    if (filterIdx === -1) return;
    state.filteredDocuments.splice(filterIdx, 1, document);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredDocuments.findIndex(
      (filterDocument) => filterDocument._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredDocuments[filterIdx].status = status;
  },

  removeDocument: (state, id) => {
    state.list = state.list.filter(
      (document) => document._id.toString() !== id.toString()
    );
    state.filteredDocuments = state.filteredDocuments.filter(
      (filteredDoc) => filteredDoc._id.toString() !== id.toString()
    );
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),
  sortDocument: (state, by) => {
    state.sort.by = by;

    if (
      by === "doctype" ||
      by === "group" ||
      by === "subgroup" ||
      by === "sub_subgroup"
    )
      state.filteredDocuments = state.filteredDocuments.sort((a, b) => {
        const sortA = a[by] ? a[by]?.name : "z";
        const sortB = b[by] ? b[by]?.name : "z";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else
      state.filteredDocuments = state.filteredDocuments.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );

    if (!state.sort.asc)
      state.filteredDocuments = state.filteredDocuments.reverse();
  },
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
