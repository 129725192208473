<template>
  <div
    class="
      bg-white
      shadow-md
      rounded-lg
      text-sm
      absolute
      top-0
      right-0
      w-32
      z-50
    "
  >
    <button
      @click.prevent="onChange(val.value)"
      class="
        py-1.5
        px-3
        hover:bg-WALIGHTBLUE-50
        block
        w-full
        text-right text-gray-700
      "
      v-for="val in data"
      :key="val"
    >
      {{ val.value }}
    </button>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "close"],
  methods: {
    onChange(val) {
      this.$emit("update:modelValue", val);
      this.$emit("close");
    },
  },
  props: ["data", "modelValue"],
};
</script>

