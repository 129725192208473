import http from "../../services/httpClient";

const state = {
  list: [],
  filteredForms: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredForms: (state) => state.filteredForms,
};

const actions = {
  getForms: async ({ commit }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.get(`/admin/forms`);

      commit("setLoading", false);
      commit("setList", data);
      commit("setFilteredForm", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.put(`/admin/forms/${id}/status`, { status });

      commit("setLoading", false);
      commit("updateStatus", { status, id });
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  filterForm: ({ commit, getters }, filterVal) => {
    const { name, formNumber, group, type, subgroup, sub_subgroup, status } =
      filterVal;

    const newFilter = getters["list"].filter((form) => {
      const groupL = form.group ? form.group?.name : "";
      const subgroupL = form.subgroup ? form.subgroup?.name : "";
      const sub_subgroupL = form.sub_subgroup ? form.sub_subgroup?.name : "";

      return (
        form.name.toLowerCase().includes(name.toLowerCase()) &&
        form.formNumber.toString().includes(formNumber.toString()) &&
        groupL.toLowerCase().includes(group.toLowerCase()) &&
        form.type.toLowerCase().includes(type.toLowerCase()) &&
        subgroupL.toLowerCase().includes(subgroup.toLowerCase()) &&
        sub_subgroupL.toLowerCase().includes(sub_subgroup.toLowerCase()) &&
        form.status.toLowerCase().includes(status.toLowerCase())
      );
    });

    commit("setFilteredForm", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredForm: (state, filteredForms) =>
    (state.filteredForms = filteredForms),

  addForm: (state, form) => {
    const idx = state.list.findIndex((g) => g._id === form._id);
    if (idx < 0) state.list.unshift(form);

    const filterIdx = state.filteredForms.findIndex(
      (filteredForm) => filteredForm._id === form._id
    );
    if (filterIdx < 0) state.filteredForms.unshift(form);
  },

  updateForm: (state, form) => {
    const idx = state.list.findIndex((list) => list._id === form._id);
    const filterIdx = state.filteredForms.findIndex(
      (filteredForm) => filteredForm._id.toString() === form._id.toString()
    );

    if (idx === -1) return;
    state.list.splice(idx, 1, form);

    if (filterIdx === -1) return;
    state.filteredForms.splice(filterIdx, 1, form);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredForms.findIndex(
      (filteredForm) => filteredForm._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredForms[filterIdx].status = status;
  },

  removeForm: (state, id) => {
    state.list = state.list.filter(
      (form) => form._id.toString() !== id.toString()
    );
    state.filteredForms = state.filteredForms.filter(
      (filteredForm) => filteredForm._id.toString() !== id.toString()
    );
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),
  sortForm: (state, by) => {
    state.sort.by = by;

    if (by === "group" || by === "subgroup" || by === "sub_subgroup")
      state.filteredForms = state.filteredForms.sort((a, b) => {
        const sortA = a[by] ? a[by]?.name : "";
        const sortB = b[by] ? b[by]?.name : "";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else if (by === "formNumber") {
      state.filteredForms = state.filteredForms.sort((a, b) =>
        a[by] > b[by] ? 1 : -1
      );
    } else
      state.filteredForms = state.filteredForms.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );
    if (!state.sort.asc) state.filteredForms = state.filteredForms.reverse();
  },
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
