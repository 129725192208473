<template>
  <div
    class="absolute py-3 px-4 bg-white shadow-2xl z-50 rounded-lg right-0 bottom-0"
    v-if="show"
  >
    <p class="my-3">{{ $t("warn-delete") }}</p>
    <div class="flex items-center justify-end gap-x-2">
      <button
        type="button"
        class="py-1 px-3 rounded text-sm text-WADARKBLUE-500 hover:bg-WADARKBLUE-400 hover:text-white"
        @click.prevent="onClickNo"
      >
        {{ $t("no") }}
      </button>
      <button
        class="py-1 px-3 text-red-600 hover:bg-red-600 hover:text-white rounded text-sm"
        type="button"
        @click.prevent="onClickYes"
      >
        {{ $t("yes") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show"],
  emits: ["no", "yes"],
  methods: {
    onClickNo() {
      this.$emit("no", false);
    },
    onClickYes() {
      this.$emit("yes", true);
    },
  },
};
</script>
