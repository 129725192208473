<template>
  <div class="relative">
    <button @click="toggleFilter" class="flex items-center">
      <img src="/icon-filters.svg" alt="filter" />
    </button>
    <Transition name="slide-down-fade">
      <div class="absolute right-0 top-10 z-10 filter_class" v-if="showFilter">
        <div
          class="flex flex-col items-center justify-end gap-2 p-3 bg-white shadow-md rounded-xl w-full"
        >
          <select-input
            :data="getSubmissionNumberData('submissionNumber')"
            :placeholder="$t('submission-number')"
            :filterMode="true"
            v-model="submissionNumber"
            size="small"
            :width="'full'"
          />

          <select-input
            :data="getObjInputData('submittedBy')"
            :placeholder="$t('submitter')"
            :filterMode="true"
            v-model="submittedBy"
            size="small"
            :width="'full'"
          />

          <select-input
            :data="getInputData('name')"
            :placeholder="$t('name')"
            :filterMode="true"
            v-model="name"
            size="small"
            :width="'full'"
          />

          <select-input
            :data="getFormInputData('group')"
            :placeholder="$t('group')"
            :filterMode="true"
            v-model="group"
            size="small"
            :width="'full'"
          />

          <select-input
            :data="getFormInputData('subgroup')"
            :placeholder="$t('sub-group')"
            :filterMode="true"
            v-model="subgroup"
            size="small"
            :width="'full'"
          />

          <select-input
            :data="getFormInputData('sub_subgroup')"
            :placeholder="$t('sub-sub-group')"
            :filterMode="true"
            v-model="sub_subgroup"
            size="small"
            :width="'full'"
          />

          <select-input
            :data="statusData"
            :placeholder="$t('status')"
            :filterMode="true"
            v-model="status"
            size="small"
            :width="'full'"
          />
        </div></div
    ></Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";

export default {
  computed: {
    ...mapGetters("submissions", ["list"]),
  },
  components: { SelectInput },

  data() {
    return {
      name: "",
      group: "",
      subgroup: "",
      sub_subgroup: "",
      submissionNumber: "",
      status: "",
      submittedBy: "",
      filterData: {
        name: "",
        group: "",
        subgroup: "",
        sub_subgroup: "",
        submissionNumber: "",
        submittedBy: "",
        status: "",
      },
      statusData: [
        { id: 1, value: "Eingereicht" },
        { id: 2, value: "In Prüfung" },
        { id: 2, value: "Abgeschlossen" },
      ],
      showFilter: false,
    };
  },
  methods: {
    ...mapActions("submissions", ["filterSubmission"]),

    getInputData(name) {
      const dupData = [];
      const allData = this.list.map((li, idx) => ({
        id: idx,
        value: li?.form ? li.form[name] : "",
      }));

      allData.forEach((dt) => {
        const idx = dupData.findIndex((u) => u.value === dt.value);
        if (idx < 0) dupData.push(dt);
      });

      return dupData;
    },

    getFormInputData(name) {
      const data = JSON.parse(JSON.stringify(this.list));
      let dupData = [];

      const allData = data
        .filter((li) => li.form && li.form[name])
        .map((li, idx) => ({
          id: idx,
          value: li.form[name]?.name.toString(),
        }));

      allData.forEach((dt) => {
        const idx = dupData.findIndex((u) => u.value === dt.value);
        if (idx < 0) dupData.push(dt);
      });

      return dupData;
    },

    getObjInputData(name) {
      const data = JSON.parse(JSON.stringify(this.list));
      let dupData = [];

      const allData = data.map((li, idx) => ({
        id: idx,
        value: li[name] ? li[name].name.toString() : "",
      }));

      allData.forEach((dt) => {
        const idx = dupData.findIndex((u) => u.value === dt.value);
        if (idx < 0) dupData.push(dt);
      });

      return dupData;
    },

    getSubmissionNumberData(name) {
      return this.list.map((s, idx) => ({ id: idx, value: s[name] }));
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showFilter = false;
      }
    },
  },

  watch: {
    name(newVal) {
      this.filterData = { ...this.filterData, name: newVal };
      this.filterSubmission(this.filterData);
    },

    group(newVal) {
      this.filterData = { ...this.filterData, group: newVal };
      this.filterSubmission(this.filterData);
    },

    subgroup(newVal) {
      this.filterData = { ...this.filterData, subgroup: newVal };
      this.filterSubmission(this.filterData);
    },

    sub_subgroup(newVal) {
      this.filterData = { ...this.filterData, sub_subgroup: newVal };
      this.filterSubmission(this.filterData);
    },

    submissionNumber(newVal) {
      this.filterData = { ...this.filterData, submissionNumber: newVal };
      this.filterSubmission(this.filterData);
    },

    submittedBy(newVal) {
      this.filterData = { ...this.filterData, submittedBy: newVal };
      this.filterSubmission(this.filterData);
    },

    status(newVal) {
      this.filterData = { ...this.filterData, status: newVal };
      this.filterSubmission(this.filterData);
    },
  },

  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
