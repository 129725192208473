import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import store from "../store";
import { accessTokenKey } from "../services/authServcies";

const isAdmin = (name) => (_, _1, next) => {
  const currentUser = store.getters.currentUser;
  let isUserAdmin = currentUser?.role && currentUser?.role[name]?.admin;

  let isSuper = currentUser?.isSuper;

  if (isSuper || isUserAdmin) return next();
  else return next("/");
};

const verifyRole = (name) => (_, _1, next) => {
  const currentUser = store.getters.currentUser;

  let isAdmin =
    currentUser && currentUser?.role && currentUser?.role[name]?.admin;
  let isUser =
    currentUser && currentUser?.role && currentUser?.role[name]?.user;
  let isEditor =
    currentUser && currentUser?.role && currentUser?.role[name]?.edit;
  let isSuper = currentUser?.isSuper;

  if (isUser || isEditor || isAdmin || isSuper) return next();
  return next("/");
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [isAdmin("user")],
  },

  {
    path: "/groups",
    name: "Groups",
    component: () => import("../views/Groups.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [verifyRole("group")],
  },

  {
    path: "/groups/:id",
    name: "SubGroups",
    component: () => import("../views/SubGroups.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [verifyRole("group")],
  },

  {
    path: "/subgroups/:id",
    name: "SubSubGroups",
    component: () => import("../views/Sub-SubGroups.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [verifyRole("group")],
  },

  {
    path: "/roles",
    name: "Roles",
    component: () => import("../views/Roles.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [isAdmin("role")],
  },

  {
    path: "/forms",
    name: "Forms",
    component: () => import("../views/Forms.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [isAdmin("form")],
  },

  {
    path: "/submissions",
    name: "Submissions",
    component: () => import("../views/Submissions.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/submissions/new",
    name: "NewSubmission",
    component: () => import("../views/NewSubmission.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/submissions/:id",
    name: "SubmissionDetail",
    component: () => import("../views/SubmissionDetail.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/doctypes",
    name: "DocTypes",
    component: () => import("../views/DocTypes.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [isAdmin("document")],
  },

  {
    path: "/documents",
    name: "Documents",
    component: () => import("../views/Documents.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [verifyRole("document")],
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { requiresUnAuth: true },
  },

  {
    path: "/password/forgotpassword",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: { requiresUnAuth: true },
  },

  {
    path: "/password/resetpassword/:resettoken",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: { requiresUnAuth: true },
  },

  {
    path: "/:notfound(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

router.beforeEach(async (to, _, next) => {
  const currentUser = store.getters.currentUser;
  const accessToken = localStorage.getItem(accessTokenKey);

  if (to.meta.requiresAuth) {
    if (!accessToken || !store.getters.accessToken) {
      return next({ path: "/login" });
    }

    if (!currentUser?._id) {
      await store.dispatch("fetchMe");
      return next();
    }

    return next();
  } else if (to.meta.requiresUnAuth && store.getters.accessToken) {
    next("/");
  } else {
    next();
  }
});

export default router;
