<template>
  <div class="bg-white mt-7">
    <div
      class="
        flex flex-col
        items-center
        max-w-screen-2xl
        mx-auto
        px-6
        py-4
        space-x-4
        md:flex-row md:justify-between
      "
    >
      <div>
        <img src="/wa-logo.png" class="object-contain pr-6 w-40" />
      </div>
      <div class="my-4">
        <p class="text-gray-300 text-xs">Immoland 1.0.8</p>
      </div>
      <div>
        <a
          href="https://wieland-assekuranz.de/impressum/"
          class="
            font-light
            hover:text-WAORANGE-500
            px-3
            py-2
            rounded-md
            text-WADARKBLUE-500 text-sm
          "
          target="_blank"
          >Impressum</a
        ><a
          href="https://wieland-assekuranz.de/datenschutz/"
          class="
            font-light
            hover:text-WAORANGE-500
            px-3
            py-2
            rounded-md
            text-WADARKBLUE-500 text-sm
          "
          target="_blank"
          >Datenschutzerklärung</a
        >
      </div>
    </div>
  </div>
</template>
