import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import "./assets/styles.css";
import "./assets/fonts.css";
import store from "./store/index";
import i18n from "./i18n";

createApp(App).use(router).use(store).use(i18n).mount("#app");
