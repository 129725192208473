import http from "../../services/httpClient";

const state = {
  list: [],
  filteredRoles: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredRoles: (state) => state.filteredRoles,
};

const actions = {
  getRoles: async ({ commit }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);

      const { data } = await http.get(`/admin/roles`);

      commit("setLoading", false);
      commit("setList", data);
      commit("setFilteredRole", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },
  filterRole: ({ commit, getters }, filterVal) => {
    const { name } = filterVal;

    const newFilter = getters["list"].filter((role) =>
      role.name.toLowerCase().includes(name.toLowerCase())
    );

    commit("setFilteredRole", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredRole: (state, filteredRoles) =>
    (state.filteredRoles = filteredRoles),

  addRole: (state, role) => {
    const idx = state.list.findIndex((g) => g._id === role._id);
    if (idx < 0) state.list.unshift(role);

    const filterIdx = state.filteredRoles.findIndex(
      (filteredRole) => filteredRole._id === role._id
    );
    if (filterIdx < 0) state.filteredRoles.unshift(role);
  },

  updateRole: (state, role) => {
    const idx = state.list.findIndex((list) => list._id === role._id);
    const filterIdx = state.filteredRoles.findIndex(
      (filteredRole) => filteredRole._id.toString() === role._id.toString()
    );

    if (idx === -1) return;
    state.list.splice(idx, 1, role);

    if (filterIdx === -1) return;
    state.filteredRoles.splice(filterIdx, 1, role);
  },

  removeRole: (state, id) => {
    state.list = state.list.filter(
      (role) => role._id.toString() !== id.toString()
    );
    state.filteredRoles = state.filteredRoles.filter(
      (filteredRole) => filteredRole._id.toString() !== id.toString()
    );
  },

  setError: (state, error) => (state.error = error),
  setLoading: (state, loading) => (state.loading = loading),
  sortRole: (state, by) => {
    state.sort.by = by;

    if (by === "users")
      state.filteredRoles = state.filteredRoles.sort((a, b) =>
        a[by].length > b[by].length ? 1 : -1
      );
    else
      state.filteredRoles = state.filteredRoles.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );

    if (!state.sort.asc) state.filteredRoles = state.filteredRoles.reverse();
  },
  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
