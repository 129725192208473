<template>
  <img src="/loader.svg" alt="loader" />
</template>

<style scoped>
img {
  width: 50px;
  object-fit: contain;
  margin: 1rem auto;
}
</style>
