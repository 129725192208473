<template>
  <form @submit.prevent="submit">
    <the-alert
      v-if="errorMsg"
      :text="errorMsg"
      @onClose="errorMsg = null"
      variant="error"
    ></the-alert>

    <the-alert
      v-if="error"
      :text="error"
      variant="error"
      @onClose="setError(null)"
    ></the-alert>

    <the-alert
      v-if="msg"
      :text="msg"
      @onClose="msg = null"
      variant="success"
    ></the-alert>

    <div class="flex flex-col sm:flex-row items-center gap-3 mb-2">
      <input
        class="rounded w-full"
        :placeholder="$t('old-password')"
        type="password"
        v-model="currentPassword"
      />
    </div>
    <div class="flex items-center flex-col sm:flex-row gap-3 mb-2">
      <input
        class="rounded w-full"
        :placeholder="$t('new-password')"
        type="password"
        v-model="newPassword"
      />
    </div>
    <div class="flex flex-col sm:flex-row items-center gap-3 mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('repeat-new-password')"
        type="password"
        v-model="confirmPassword"
      />
    </div>

    <div class="flex relative items-center justify-end gap-3">
      <button
        :disabled="loading"
        type="submit"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{ loading ? `Loading` : $t("change-password") }}
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TheAlert from "@/components/alerts/TheAlert.vue";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      msg: null,
      errorMsg: null,
    };
  },
  methods: {
    ...mapActions(["changePassword"]),
    ...mapMutations(["setError"]),
    async submit() {
      this.msg = null;
      this.errorMsg = null;

      if (!this.currentPassword || !this.newPassword || !this.confirmPassword)
        return (this.errorMsg = "Please provide in all fields");

      if (this.newPassword !== this.confirmPassword)
        return (this.errorMsg = "Password don't match");

      const newMe = await this.changePassword({
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      });

      if (newMe) this.msg = "Updated successfully";
    },
  },
  emits: ["onClose"],
  computed: {
    ...mapGetters(["loading", "error"]),
  },
  components: { TheAlert },
  props: ["me"],
  unmounted() {
    this.setError(null);
  },
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
