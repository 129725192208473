import http from "../../services/httpClient";

const state = {
  list: [],
  filteredUsers: [],
  sort: { by: "", asc: false },
  error: null,
  loading: false,
};

const getters = {
  list: (state) => state.list,
  loading: (state) => state.loading,
  error: (state) => state.error,
  sort: (state) => state.sort,
  filteredUsers: (state) => state.filteredUsers,
};

const actions = {
  getUsers: async ({ commit }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.get(`/admin/users`);
      commit("setLoading", false);

      commit("setList", data);
      commit("setFilteredUser", data);
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  changeStatus: async ({ commit }, { status, id }) => {
    try {
      commit("setError", null);
      commit("setLoading", true);
      const { data } = await http.put(`/admin/users/${id}/status`, { status });

      commit("setLoading", false);
      commit("updateStatus", { status, id });
      return data;
    } catch (err) {
      commit("setLoading", false);
      commit(
        "setError",
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error || err.message
      );
    }
  },

  filterUser: ({ commit, getters }, filterVal) => {
    const { name, email, phone, status, role } = filterVal;
    const newFilter = getters["list"].filter(
      (user) =>
        user.name.toLowerCase().includes(name.toLowerCase()) &&
        user.email.toLowerCase().includes(email.toLowerCase()) &&
        (user.phone
          ? user?.phone?.toLowerCase().includes(phone.toLowerCase())
          : "".includes(phone.toLowerCase())) &&
        user.status.toLowerCase().includes(status.toLowerCase()) &&
        user?.role?.name.toLowerCase().includes(role.toLowerCase())
    );

    commit("setFilteredUser", newFilter);
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),

  setFilteredUser: (state, filteredUsers) =>
    (state.filteredUsers = filteredUsers),

  addUser: (state, user) => {
    state.list.unshift(user);

    const filterIdx = state.filteredUsers.findIndex(
      (filteredUser) => filteredUser.id === user.id
    );
    if (filterIdx < 0) state.filteredUsers.unshift(user);
  },

  updateUser: (state, user) => {
    const idx = state.list.findIndex((li) => li.id === user.id);
    const filterIdx = state.filteredUsers.findIndex(
      (filteredUser) => filteredUser.id === user.id
    );
    if (idx === -1) return;

    state.list.splice(idx, 1, user);
    if (filterIdx !== -1) state.filteredUsers.splice(filterIdx, 1, user);
  },

  updateStatus: (state, { status, id }) => {
    const idx = state.list.findIndex((list) => list._id === id);
    const filterIdx = state.filteredUsers.findIndex(
      (filteredUser) => filteredUser._id.toString() === id.toString()
    );

    if (idx === -1) return;
    state.list[idx].status = status;

    if (filterIdx === -1) return;
    state.filteredUsers[filterIdx].status = status;
  },

  removeUser: (state, id) => {
    state.list = state.list.filter(
      (user) => user.id.toString() !== id.toString()
    );
    state.filteredUsers = state.filteredUsers.filter(
      (filteredUser) => filteredUser.id.toString() !== id.toString()
    );
  },

  setError: (state, error) => (state.error = error),

  setLoading: (state, loading) => (state.loading = loading),

  sortUser: (state, by) => {
    state.sort.by = by;

    if (by === "role")
      state.filteredUsers = state.filteredUsers.sort((a, b) => {
        const sortA = a[by] ? a[by]?.name : "z";
        const sortB = b[by] ? b[by]?.name : "z";

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else if (by === "groups")
      state.filteredUsers = state.filteredUsers.sort((a, b) => {
        const sortA = a[by].length
          ? a[by].map((r) => r?.name)[0].toLowerCase()
          : "z"; // z is to put empty field at the end

        const sortB = b[by].length
          ? b[by].map((r) => r?.name)[0].toLowerCase()
          : "z"; // z is to put empty field at the end

        return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
      });
    else
      state.filteredUsers = state.filteredUsers.sort((a, b) =>
        a[by].toLowerCase() > b[by].toLowerCase() ? 1 : -1
      );

    if (!state.sort.asc) state.filteredUsers = state.filteredUsers.reverse();
  },

  toggleSort: (state) => (state.sort.asc = !state.sort.asc),
};

export default {
  state,
  getters,
  namespaced: true,
  actions,
  mutations,
};
