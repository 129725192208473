<template>
  <div
    @click.prevent="closeMenu"
    :class="{ flex: show, hidden: !show }"
    class="small-menu absolute right-0 top-12 flex flex-col bg-white z-50 w-40 rounded-xl p-1"
  >
    <button
      @click.prevent="$emit('openProfile')"
      class="text-left font-semibold hover:bg-WALIGHTBLUE-50 py-2.5 px-4 text-gray-600 text-sm rounded-lg"
    >
      {{ $t("settings") }}
    </button>
    <button
      @click="$emit('logout')"
      class="font-semibold hover:bg-WALIGHTBLUE-50 py-2.5 px-4 text-left text-WADARKBLUE-500 text-sm rounded-lg"
    >
      {{ $t("logout") }}
    </button>
  </div>
</template>

<script>
export default {
  emits: ["openProfile", "logout", "closeMenu"],
  methods: {
    closeMenu(e) {
      if (!this.$el.contains(e.target)) this.$emit("closeMenu", e);
    },
  },

  mounted() {
    window.document.addEventListener("click", this.closeMenu);
  },

  unmounted() {
    window.document.removeEventListener("click", this.closeMenu);
  },
  props: ["show"],
};
</script>

<style scoped>
a.active {
  background-color: #f1f7f8;
}
</style>
