<template>
  <Teleport to="body" v-if="show">
    <div
      class="opaque fixed top-0 right-0 w-screen h-screen flex items-center justify-center z-50 bg-gray-700"
    >
      <div class="modal bg-white rounded-lg p-5 overflow-y-auto">
        <div class="flex items-center justify-between">
          <h3 class="text-base text-gray-500">
            <slot name="header">Header</slot>
          </h3>
          <button
            @click="onClose"
            class="block h-10 w-10 rounded-3xl hover:bg-gray-200 text-2xl text-gray-600"
          >
            &times;
          </button>
        </div>
        <div class="my-3">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  props: ["show", "class"],
  emits: ["onClose"],
  methods: {
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.modal {
  width: auto;
  min-height: 200px;
  max-height: 95vh;
}

.opaque {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
