<template>
  <div
    class="py-3 px-5 mb-2 rounded flex items-center justify-between"
    :class="{
      'text-green-600': variant === 'success',
      'bg-green-50': variant === 'success',
      'text-red-400': variant === 'error',
      'bg-red-50': variant === 'error',
      'text-gray-800': !variant,
      'bg-gray-100': !variant,
    }"
  >
    <span> {{ text }}</span>
    <button type="button" @click="$emit('onClose')">&times;</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      warning: "error",
      success: "success",
    };
  },
  emits: ["onClose"],
  props: ["text", "variant"],
};
</script>