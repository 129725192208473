<template>
  <button @click.prevent="emit('btnClick')" class="p-3 hover:bg-white rounded">
    <img src="/three-dot.svg" alt="edit" class="hover:scale-125" />
  </button>
  <div
    v-if="props.show"
    class="absolute bg-white shadow-md min-w-[112px] top-0 rounded z-10 overflow-hidden"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onBeforeMount, onBeforeUnmount } from "vue";

const props = defineProps({ show: Boolean });
const emit = defineEmits(["btnClick", "onClose"]);

onBeforeMount(() =>
  document.addEventListener("click", (e) => emit("onClose", e))
);
onBeforeUnmount(() =>
  document.removeEventListener("click", (e) => emit("onClose", e))
);
</script>
