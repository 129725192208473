<template>
  <form @submit.prevent="submit">
    <the-alert
      v-if="error"
      :text="error"
      variant="error"
      @onClose="setError(null)"
    />
    <the-alert v-if="msg" :text="msg" variant="success" @onClose="msg = null" />
    <div class="flex flex-col sm:flex-row items-center gap-3 mb-4">
      <input
        class="rounded w-full"
        placeholder="First name"
        type="text"
        v-model="firstName"
      />

      <input
        class="rounded w-full"
        placeholder="Last name"
        type="text"
        v-model="lastName"
      />
    </div>
    <div class="flex items-center flex-col sm:flex-row gap-3 mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('email')"
        type="email"
        v-model="email"
      />

      <input
        class="rounded w-full"
        :placeholder="$t('phone')"
        type="tel"
        v-model="phone"
      />
    </div>
    <div class="flex items-center flex-row gap-3">
      <div class="flex items-center text-sm w-1/2">
        <p class="text-gray-600 mr-1">Your Role:</p>
        <p class="text-WAGREEN-500">
          {{ currentUser?.isSuper ? "Super Admin" : role }}
        </p>
      </div>

      <div class="flex items-center text-sm w-1/2">
        <p class="text-gray-600 mr-1">Your Status:</p>
        <p class="text-WAGREEN-500">{{ status }}</p>
      </div>
    </div>

    <div class="flex relative items-center justify-between ap-3 pt-4">
      <button
        @click="$emit('change-password')"
        type="button"
        class="
          border
          py-2
          px-4
          rounded-lg
          text-sm
          border-WAORANGE-500
          hover:bg-white
        "
      >
        Change Password
      </button>

      <button
        :disabled="loading"
        type="submit"
        class="rounded-lg text-white py-2 px-4 bg-WAORANGE-500 text-sm"
      >
        {{ loading ? `Loading` : $t("edit") }}
      </button>
    </div>
  </form>
</template>

<script>
import TheAlert from "@/components/alerts/TheAlert.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { TheAlert },

  created() {
    this.fetchMe();
  },

  data() {
    return {
      firstName: this.me?.firstName,
      lastName: this.me?.lastName,
      email: this.me?.email,
      phone: this.me?.phone,
      status: this.me?.status,
      role: this.me?.role?.name,
      msg: null,
    };
  },

  methods: {
    ...mapActions(["updateMe", "fetchMe"]),
    ...mapMutations(["setError"]),
    async submit() {
      this.msg = null;

      const newMe = await this.updateMe({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
      });

      if (newMe) this.msg = "Edited successfully";
    },
  },
  emits: ["onClose"],
  computed: {
    ...mapGetters(["currentUser", "loading", "error"]),
  },
  props: ["me"],
  unmounted() {
    this.setError(null);
  },
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
