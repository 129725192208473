<template>
  <div
    class="cont relative"
    :class="{
      small: size === 'small',
      full: size === 'full' || width === 'full',
    }"
  >
    <input
      @click="openDropdown"
      type="text"
      :placeholder="placeholder || $t('select')"
      class="rounded cursor-pointer input-btn text-gray-700 truncate"
      :value="defaultVal || value"
      @input="oninput"
      :readonly="!filterMode"
      :class="{ 'w-full': size === 'full' || width === 'full' }"
      :disabled="disabled"
      :autocomplete="'off'"
      :required="required"
    />

    <div class="absolute top-0 w-10 right-0 z-20 bg-red-400 h-auto">
      <img
        v-if="filterMode && value"
        @click="resetData"
        src="/close-round-light.svg"
        class="close w-6 absolute right-9 cursor-pointer top-1.5"
      />
      <img
        @click="showDropdown ? (showDropdown = false) : (showDropdown = true)"
        :class="{ active: showDropdown }"
        src="/expand-down-light.svg"
        class="cursor-pointer w-6 absolute right-2 top-2"
      />
    </div>
    <Transition name="slide-down-fade">
      <div
        @click.prevent="closeDropdown"
        v-if="showDropdown"
        class="
          dropdown
          overflow-hidden
          absolute
          top-11
          bg-white
          shadow-2xl
          w-full
          rounded
          h-auto
          max-h-80
          z-50
        "
      >
        <div v-if="filteredData.length === 0" class="py-2 px-4">
          <span class="font-semibold text-gray-600">{{ $t("no-data") }}</span>
        </div>
        <button
          v-for="data in filteredData"
          :key="data.id"
          class="
            block
            py-2
            px-4
            hover:bg-WALIGHTBLUE-50
            w-full
            text-left text-gray-700
            truncate
          "
          type="button"
          @click="onchange(data)"
        >
          {{ data.value }}
        </button>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: [
    "placeholder",
    "data",
    "modelValue",
    "filterMode",
    "size",
    "width",
    "disabled",
    "required",
    "returnId", //returns id of data instead of value for case when Id needed (used on user assign form)
    "isObject", // returns object itself with updated id and then id is used somewhere else ( used on user form role )
    "defaultVal",
  ],
  emits: ["update:modelValue"],

  data() {
    return {
      value: this.isObject ? this.modelValue?.name : this.modelValue,
      showDropdown: false,
      filteredData: [],
    };
  },

  methods: {
    onchange(data) {
      this.value = data.value;
      if (this.returnId)
        this.$emit(
          "update:modelValue",
          this.isObject ? { ...this.isObject, id: data.id } : data.id
        );
      else this.$emit("update:modelValue", data.value);
      this.showDropdown = false;
    },

    oninput(e) {
      this.value = e.target.value;
      this.filteredData = this.data.filter((data) =>
        typeof data.value === "string"
          ? data.value.toLowerCase().includes(e.target.value.toLowerCase())
          : data.value
              .toString()
              .toLowerCase()
              .includes(e.target.value.toString().toLowerCase())
      );
    },

    openDropdown() {
      this.showDropdown = true;
    },

    closeDropdown(e) {
      e?.stopPropagation();
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },

    // reset form
    resetData(e) {
      e?.stopPropagation();
      this.filteredData = this.data;
      this.$emit(
        "update:modelValue",
        this.isObject ? { id: "", name: "" } : ""
      );

      this.value = "";
    },
  },
  created() {
    this.filteredData = this.data;
  },

  watch: {
    data(newVal) {
      this.filteredData = newVal;
    },
  },

  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },
};
</script>

<style scoped>
.dropdown {
  overflow-y: scroll;
}

.dropdown,
.input-btn {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

img {
  transition: 0.2s ease;
}

img.active {
  transform: rotate(180deg);
  transition: 0.2s ease;
}

.small input {
  padding: 5px 12px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04);
}

.small img {
  width: 20px;
}

.small .dropdown {
  top: 35px !important;
}

.full {
  width: 100%;
}
.cont {
  min-height: 40px;
}
</style>
