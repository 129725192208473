export default {
  "sign-in-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your account"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "forgot-password-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "remember-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Password?"])},
  "forgot-password-email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email, please check your inbox"])},
  "reset-password-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your new password"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat New Password"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "submissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submissions"])},
  "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "add-submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Submission"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Group"])},
  "sub-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Sub Group"])},
  "form-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Number"])},
  "submitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitter"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviert"])},
  "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingereicht"])},
  "under-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unter Überprüfung"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "select-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Form"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "add-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Form"])},
  "form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Name"])},
  "add-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Field"])},
  "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
  "field-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
  "field-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Type"])},
  "field-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Placeholder"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text area"])},
  "radio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio"])},
  "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkbox"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "create-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new user"])},
  "add-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group"])},
  "assign-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign User"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
  "select-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select group"])},
  "select-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select User"])},
  "add-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Role"])},
  "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Name"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "edit-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Role"])},
  "add-document-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenttyp hinzufügen"])},
  "document-type-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenttypname"])},
  "user-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername"])},
  "submissions-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen heute"])},
  "submissions-this-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen diese Woche"])},
  "submissions-this-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen diesen Monat"])},
  "Submissions-all-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen alle Zeiten"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente"])},
  "add-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument hochladen"])},
  "document-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentarten"])},
  "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "document-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentart"])},
  "document-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentname"])},
  "select-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unter-Gruppe auswählen"])},
  "select-sub-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unter-Unter-Gruppe auswählen"])},
  "add-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untergruppe hinzufügen"])},
  "add-sub-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unteruntergruppe hinzufügen"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "warn-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete?"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "form-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Type"])},
  "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to show"])},
  "404-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
  "404-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! We're sorry, 404"])},
  "404-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are followed by is may be broken or removed by us, Please go back to home page"])},
  "404-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Home"])},
  "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "field-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field comment"])},
  "new-submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Submission"])},
  "invalid-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name should be with out space"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "submission-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "submission-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "email-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Notifications"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])}
}